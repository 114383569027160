import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';
import { ProjectService } from 'src/app/shared/services/project/project.service';
import ValidationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrl: './dashboard-menu.component.scss',
})
export class DashboardMenuComponent implements OnInit {
  public activeIndex: number;
  public activeSubmenuIndex: number;
  menuOpen: boolean = true;
  public popupVisible = false;
  public projectList: any = [];
  public selectedProjectId: any = null;

  public open = new Map<number, boolean>([
    [2, false],
    [4, false],
    [6, false],
  ]);
  public isOpen = false;

  public selectedNgo: any = null;
  public sessionUser: any;
  public userNgoList: any = [];

  constructor(
    private ngoService: NgoService,
    private readonly router: Router,
    private readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService,
    private readonly commonSharedService: CommonSharedServiceService,
    public readonly projectService: ProjectService
  ) {}

  public ngOnInit(value?: any): void {
    const user = this.localStorageService.getSessionUser();

    const aclMenu = value || this.getACLFromUrl();
    this.activeIndex = aclMenu?.menu_index;

    const selectedNgo = this.localStorageService.getNgoInLocalStorage();
    if (selectedNgo) {
      this.selectedNgo = selectedNgo;
    }
    this.getNgoByUserId();
    this.getProjectsByNgoID();
    this.commonSharedService.ngoSelectionAction$.subscribe((action) => {
      this.getProjectsByNgoID();
    });
  }

  private getProjectsByNgoID(): void {
    this.selectedNgo = this.localStorageService.getNgoInLocalStorage();
    if (this.selectedNgo) {
      this.projectList = [];
      this.utilitiesService.showSpinner(true);
      this.projectService.getProjectsByNgoId(this.selectedNgo?.id).subscribe({
        next: (res) => {
          this.projectList = res;
          this.utilitiesService.showSpinner(false);
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
        },
      });
    } else {
      this.utilitiesService.showSwalAndReturn({
        type: 'warning',
        title: `You must select an NGO first!`,
        confirmButtonText: 'OK',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      });
    }
  }

  public form() {
    const { isValid } = ValidationEngine.validateGroup('selectValidationGrp');
    if (isValid) {
      if (this.selectedProjectId) {
        const selectedProject = this.projectList.find(
          (project) => project.id === this.selectedProjectId
        );
        if (selectedProject) {
          // console.log('Selected Project:', selectedProject);
          this.localStorageService.setProjectInLocalStorage(selectedProject);
          this.closeModal();
          this.router.navigate(['/dashboard/form']);
        }
      }
    } else {
      const selectedProject = this.projectList.find(
        (project) => project.id === this.selectedProjectId
      );
      this.localStorageService.setProjectInLocalStorage(selectedProject);
      this.closeModal();
      this.router.navigate(['/dashboard/form']);
    }
  }

  public closeModal(): void {
    this.popupVisible = false;
  }

  public formsClicked(): void {
    // this.popupVisible = true;
    if (
      this.projectList?.length === 0 ||
      !this.localStorageService.getProjectFromLocalStorage()
    ) {
      this.popupVisible = true;
    }
    if (
      this.projectList?.length > 0 &&
      this.localStorageService.getProjectFromLocalStorage()
    ) {
      this.router.navigate(['/dashboard/form']);
    }
  }

  public getNgoByUserId(): void {
    this.sessionUser = this.localStorageService.getSessionUser();
    this.ngoService
      .getNgoByUserId(this.sessionUser?.id)
      .subscribe((result: any) => {
        this.userNgoList = result;
      });
  }

  public toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  public selectOrganization(ngo: any) {
    this.isOpen = false;
    if (!(JSON.stringify(this.selectedNgo) === JSON.stringify(ngo))) {
      this.selectedNgo = ngo;
      this.localStorageService.setNgoInLocalStorage(ngo);
      this.localStorageService.deleteProjectFromLocalStorage();
      this.triggerNgoSelection();
    }
  }

  private triggerNgoSelection() {
    this.commonSharedService.ngoSelected();
  }

  public toggleMenu(index: number): void {
    this.activeIndex = index;
    if (this.open.has(index)) {
      this.toggleOpen(index);
    }

    if (index === 4 && this.userNgoList?.length === 0) {
      this.router.navigate(['/dashboard/manage-my-ngo']);
    } else if (index === 4 && this.userNgoList?.length > 0) {
      if (!this.localStorageService.getNgoInLocalStorage()) {
        this.utilitiesService.showSwal('warning', 'Select an NGO first.');
      }
    }
  }

  public isActive(index: number): boolean {
    if (
      this.router.url === '/dashboard/form' ||
      this.router.url === '/dashboard/all-projects' ||
      this.router.url === '/dashboard/workplace'
    ) {
      return index === 4;
    } else if (
      this.router.url === '/dashboard/learn-all-ngo' ||
      this.router.url === '/dashboard/learn-news-article'
    ) {
      return index === 2;
    } else {
      return false;
    }
    // return this.activeIndex === index;
  }

  public isSubmenuActive(index: number): boolean {
    if (this.router.url === '/dashboard/form') {
      return index === 43;
    } else if (this.router.url === '/dashboard/all-projects') {
      return index === 42;
    } else if (this.router.url === '/dashboard/workplace') {
      return index === 41;
    } else if (this.router.url === '/dashboard/learn-all-ngo') {
      return index === 21;
    } else if (this.router.url === '/dashboard/learn-news-article') {
      return index === 22;
    } else {
      return false;
    }
    // return this.activeSubmenuIndex === index;
  }

  public setActiveIndex(index: number): void {
    this.activeSubmenuIndex = index;
  }

  private toggleOpen(index: number): void {
    const value = this.open.get(index);
    this.open.forEach((value, key) => {
      this.open.set(key, false);
    });
    this.open.set(index, !value);
  }

  public openCloseMenu(): void {
    // this.menuOpen = !this.menuOpen;
  }

  private getACLFromUrl(): any {
    const value = this.router.url;
    if (value.includes('manage-my-ngo')) {
      return { menu_name: 'Manage My NGO', menu_index: 4 };
    } else if (value.includes('claim-your-ngo')) {
      return { menu_name: 'Manage My NGO', menu_index: 4 };
    } else if (value.includes('register-new-ngo')) {
      return { menu_name: 'Manage My NGO', menu_index: 4 };
    } else if (value.includes('bookmarked-ngos')) {
      return { menu_name: '', menu_index: null };
    } else if (value === '/dashboard') {
      return { menu_name: 'Home', menu_index: 1 };
    }
  }
}
