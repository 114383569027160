import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable } from 'rxjs';
import {
  IPrjectResponse,
  IProjectRequest,
} from '../../interface/dashboard/program.interface';
import { HttpHeaders } from '@angular/common/http';
import { ProgramModel } from '../../model/program.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private projectApi = `${UrlEnum.SHOHAY_NGO}/project`;
  private programApi = `${UrlEnum.SHOHAY_NGO}/program`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public createProgram(
    projectData: IProjectRequest
  ): Observable<IPrjectResponse> {
    return this.httpRequestService.post(this.projectApi, projectData);
  }

  public getProgrammeByNgoId(NgoId: string): Observable<any> {
    return this.httpRequestService.get(
      `${this.programApi}/get-by-ngo/${NgoId}`
    );
  }

  public createProgramNew(body: ProgramModel): Observable<any> {
    return this.httpRequestService.post(this.programApi, body);
  }

  public getProjectsByNgoId(NgoId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.projectApi}/get-by-ngo/${NgoId}`)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }
}
